export default {
    zh:{
        sysConfig:{
            useYzm:'是否启用验证码',
            yzmType:'验证码种类',
            yzmType_0:'滑块验证码',
            yzmType_1:'数字验证码',
            showLayer:'是否显示layer',
            layerShowType:'layer显示方式',
            layerShowType_0:'顶级菜单',
            layerShowType_1:'末级菜单',
            pwdMinLen:'密码最小长度',
            pwdWrongNum:'密码最多错误次数',
            linkWay:'技术支持联系方式',
            loginMsg:'登录页文字提示',
            mainMsg:'主页动画提示文字',
            animations:'默认开启动画',
            loginAnimation:'登录动画',
            mainAnimation:'主页动画',
            listAnimation:'列表动画',
            formAnimation:'表单动画',
            dialogBgAnimation:'弹出框背景动画',
            dialogAnimation:'弹出框动画',
            tokenOutTime:'token过期处理方式',
            tokenOutTime_0:'重新生成返回给客户端',
            tokenOutTime_1:'强退重新登录',
            saveOperateLogToDb:'是否保存操作日志到数据库',
            pushOperateLogToBuffer:'是否保存操作日志到缓存',
            mulAccountOnline:'是否允许帐号多地同时在线',
            encryptData:'数据是否加密传输',
            needLimitAccessNumber:'url是否启用访问次数控制',
            limitTime:'单位时间次数限制(秒)',
            ipLockTime:'超限锁定IP时间(秒)',
            canRegister:'启用注册功能',
        }
    },
    en:{
        sysConfig:{
            useYzm:'enable verification code',
            yzmType:'Verification code type',
            yzmType_0:'Slider',
            yzmType_1:'Digital',
            showLayer:'display layer',
            layerShowType:'layer Display Type',
            layerShowType_0:'Top level menu',
            layerShowType_1:'End level menu',
            pwdMinLen:'Minimum password length',
            pwdWrongNum:'Maximum password errors',
            linkWay:'Technical Support Contact Info',
            loginMsg:'Login page text prompt',
            mainMsg:'Homepage Animation Text',
            animations:'Default on animation',
            loginAnimation:'Login Animation',
            mainAnimation:'Home Animation',
            listAnimation:'List Animation',
            formAnimation:'Form Animation',
            dialogBgAnimation:'Dialog Bg Animation',
            dialogAnimation:'Dialog Animation',
            tokenOutTime:'Token Expiration Process Type',
            tokenOutTime_0:'Rebuild returned to client',
            tokenOutTime_1:'Force logout and login again',
            saveOperateLogToDb:'save operation to database',
            pushOperateLogToBuffer:'save operation to cache',
            mulAccountOnline:'multiple online of same account',
            encryptData:'encrypted for transmission',
            needLimitAccessNumber:'Access control',
            limitTime:'per unit time limit (seconds)',
            ipLockTime:'lock IP time (seconds)',
            canRegister:'Enable registration',
        }
    }
}