import { onBeforeMount,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
import language from './sysconfigLanguage'
let form = {
    id: '',
    useYzm: true,
    yzmType:0,
    showLayer:true,
    layerShowType:0,
    pwdMinLen: 0,
    pwdWrongNum: 0,
    linkWay: '',
    loginMsg:'',
    mainMsg:'',
    tokenOutTime:0,
    canRegister:false,
    saveOperateLogToDb:false,
    mulAccountOnline:true,
    encryptData:false,
    needLimitAccessNumber:false,
    limitTime:10,
    ipLockTime:60,
    animations:'',
};
export default defineComponent({
    name: "SysConfig",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let store = proxy.$store;
        let dataObj=reactive({
            formRef:null,
            fullscreenLoading:false,
            cardParam: {
                cardButtons: [],
                modelPath: utils.Api.buildUrl("/config")
            },
            form: Object.assign({}, form),
            rules: {}
        })
        onBeforeMount(()=>{
            proxy.addLangProps(language);
        })
        onMounted(async ()=>{
            await requestCard();
        })
        const clickHandler=(ev:string)=>{
            proxy[ev]();
        }
        const requestCard=async()=>{
            dataObj.fullscreenLoading=true;
            let res = await utils.Api.postRequest({
                params:{modelType:'card'},
                url: dataObj.cardParam.modelPath + "/load"
            });
            if(res.data.animations)res.data.animations=JSON.parse(res.data.animations);//animations是数组，后台返回的是字符串，需要转一下
            dataObj.form = res.data;
            dataObj.cardParam.cardButtons = res.buttons;
            dataObj.fullscreenLoading=false;
        }
        //保存
        const saveHandler=async(inst:any)=>{
            dataObj.fullscreenLoading=true;
            let res = await utils.Api.postRequest({
                url: dataObj.cardParam.modelPath + "/save",
                params: dataObj.form
            });
            if(res.result){
                sessionStorage.setItem('encryptData',dataObj.form.encryptData+'');
                sessionStorage.setItem('mainMsg',dataObj.form.mainMsg);
                sessionStorage.setItem('showLayer',dataObj.form.showLayer+'');
                sessionStorage.setItem('layerShowType',dataObj.form.layerShowType+'');
                sessionStorage.setItem('canRegister',dataObj.form.canRegister+'');

                utils.Tools.success({
                    message: res.msg
                });
            }else{
                utils.Tools.error();
            }
            dataObj.fullscreenLoading=false;
        }

        //重置
        const resetHandler=()=>{}
        return{
            ...toRefs(dataObj),clickHandler,requestCard,saveHandler,resetHandler
        }
    },

});